<template>
  <div
    v-if="!isLoading"
    class=""
    style="height: 100vh !important"
  >
    <div v-if="orderType === '1'">
      <SingleDelivery
        :is-loading="isLoading"
        :order="order"
      />
    </div>
    <div v-else>
      <BulkDelivery
        :is-loading="isLoading"
        :order="bulkOrder"
      />
    </div>
  </div>
</template>

<script>
import SingleDelivery from '@/components/OrderSuccess/SingleDeliverySuccess.vue'
import BulkDelivery from '@/components/OrderSuccess/BulkDeliverySuccess.vue'
import axios from '@axios'
import { mapGetters } from 'vuex'

export default {
  components: {
    SingleDelivery,
    BulkDelivery,
  },
  data() {
    return {
      orderType: null,
      bulkOrder: [],
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      order: 'orders/order',
    }),
  },
  async created() {
    this.isLoading = true
    if (typeof this.$route.params.type !== 'undefined') {
      this.orderType = this.$route.params.type
    }

    if (this.orderType === '1') {
      if (typeof this.$route.params.id !== 'undefined') {
        localStorage.removeItem('order')
        await this.$store.dispatch('orders/fetchOrder', { orderId: this.$route.params.id })
      }
      this.isLoading = false
      return
    }

    if (typeof this.$route.params.id !== 'undefined') {
      const response = await axios.get(`bulk-orders/${this.$route.params.id}`)
      if (response.status === 200) {
        this.bulkOrder = response.data
      }
    }
    this.isLoading = false
  },
}
</script>

<template>
  <div
    v-if="!isLoading"
    class="p-5 d-flex flex-column justify-content-center align-items-center"
    style="height: 100vh !important"
  >
    <b-row class="py-1 w-50">
      <b-col
        md="6"
        lg="6"
        class="px-5 d-flex flex-column text-center justify-content-center align-items-center  border-right"
      >
        <div class="w-100 mb-1 ">
          <feather-icon
            color="#ea5455"
            size="160"
            icon="CheckCircleIcon"
          />
          <h1 class="mt-2">
            {{ $t('src.views.pages.inside.ordersuccessful.thankY', { '1': order.user ? order.user.name : '' }) }}
          </h1>
          <h4 class="mt-2">
            {{ $t('src.views.pages.inside.ordersuccessful.yourDIC') }}
          </h4>
          <p class="mt-2">
            {{ $t('src.views.pages.inside.ordersuccessful.youllRA') }}
          </p>
        </div>
        <b-button
          type="button"
          variant="primary"
          class="mt-3 mx-auto d-block"
          style="width: 212px;"
          @click.prevent="downloadPdf('grn')"
        >
          <span v-if="!isDownloadingGrn">{{ $t('src.views.pages.inside.ordersuccessful.printGRN') }}</span>
          <b-spinner
            v-else
            small
            :label="$t('src.views.pages.inside.ordersuccessful.dowloading')"
          />
        </b-button>
        <b-button
          type="button"
          variant="primary"
          class="mt-1 mx-auto d-block"
          style="width: 212px;"
          @click.prevent="downloadPdf('cover')"
        >
          <span v-if="!isDownloadingCover">{{ $t('src.views.pages.inside.ordersuccessful.printCN') }}</span>
          <b-spinner
            v-else
            small
            :label="$t('src.views.pages.inside.ordersuccessful.dowloading')"
          />
        </b-button>
        <b-button
          type="button"
          variant="primary"
          class="mt-1"
          style="width: 212px;"
          to="/bulk-delivery"
        >{{ $t('src.views.pages.inside.ordersuccessful.continueS') }}</b-button>
      </b-col>
      <b-col
        md="6"
        lg="6"
        class="d-flex flex-column text-center align-items-center justify-content-center"
      >
        <div class="p-2 mb-1 w-100">
          <h2 class="mb-0">
            {{ order.id }}
          </h2>
          <h5 class="mb-0">
            Bulk Order ID
          </h5>
        </div>
        <div class="p-2 mb-1 w-100">
          <h2 class="mb-0">
            {{ $helpers.formatTotal(order.total_charge) }}
          </h2>
          <h5 class="mb-0">
            {{ $t('src.views.pages.inside.ordersuccessful.amount') }}
          </h5>
        </div>
        <div class="p-2 mb-1 w-100">
          <h2 class="mb-0">
            {{ $helpers.formatDate(order.date_added) }}
          </h2>
          <h5 class="mb-0">
            {{ $t('src.views.pages.inside.ordersuccessful.orderP') }}
          </h5>
        </div>
        <div class="p-2 w-100">
          <h2 class="mb-0">
            {{ $t('src.views.pages.inside.ordersuccessful.invoice') }}
          </h2>
          <a
            class="text-primary"
            :href="order.invoice.receipt"
            target="_blank"
          >{{ $t('src.views.pages.inside.ordersuccessful.downloadYIH') }}</a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDownloadingCover: false,
      isDownloadingGrn: false,
    }
  },
  methods: {
    async downloadPdf(type) {
      let fileName = ''
      if (type === 'cover') {
        fileName = 'cover-note'
        this.isDownloadingCover = true
      }

      if (type === 'grn') {
        fileName = 'good-return-note'
        this.isDownloadingGrn = true
      }

      const response = await axios.get(`bulk-orders/${this.order.id}/print/${type}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${fileName}-${this.order.date_added}-${this.order.id}.pdf`)
      document.body.appendChild(fileLink)

      fileLink.click()
      this.isDownloadingCover = false
      this.isDownloadingGrn = false
    },
  },
}
</script>

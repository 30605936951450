<template>
  <div
    v-if="!isLoading"
    class="p-5 d-flex flex-column justify-content-center align-items-center"
    style="height: 100vh !important"
  >
    <b-container class="my-5">
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <b-row
          class="border"
        >
          <b-col class="text-center p-0">
            <div class="mb-3 mt-3 border-right">
              <feather-icon
                class="mx-2"
                color="#ea5455"
                size="160"
                icon="CheckCircleIcon"
              />
              <h1 class="mt-3">
                {{ $t('thank_you', { '1': order.user ? order.user.name : '' }) }}
              </h1>
              <h4 class="mt-2">
                {{ $t('your_delivery_is_confirmed') }}
              </h4>
              <p class="mt-2">
                {{ $t('delivery_confirmed_message') }}
              </p>
              <b-button
                type="button"
                variant="primary"
                class="mt-3 mx-auto d-block"
                style="width: 212px;"
                @click.prevent="downloadPdf('grn')"
              >
                <span v-if="!isDownloadingGrn">{{ $t('print_good_return_note') }}</span>
                <b-spinner
                  v-else
                  small
                  :label="$t('dowloading')"
                />
              </b-button>
              <b-button
                type="button"
                variant="primary"
                class="mt-1 mx-auto d-block"
                style="width: 212px;"
                @click.prevent="downloadPdf('cover')"
              >
                <span v-if="!isDownloadingCover">{{ $t('print_cover_note') }}</span>
                <b-spinner
                  v-else
                  small
                  :label="$t('dowloading')"
                />
              </b-button>
              <b-button
                type="button"
                variant="primary"
                class="mt-1"
                style="width: 212px;"
                to="/create-delivery"
              >{{ $t('continue_shopping') }}</b-button>
            </div>

          </b-col>
          <b-col
            class="text-center d-flex flex-column align-items-center justify-content-center"
          >
            <div class="m-3">
              <div v-show="!isLoading">
                <b-container class="border">
                  <b-row
                    cols="2"
                    class="border"
                  >
                    <b-col class="p-1 border d-flex flex-column align-items-center justify-content-center">
                      <h2 class="mb-0">
                        {{ order.reference_id }}
                      </h2>
                      <h5 class="mb-0">
                        {{ $t('order_id') }}
                      </h5>
                    </b-col>
                    <b-col class="p-1 border">
                      <h2 class="mb-0">
                        {{ $helpers.formatTotal(order.price) }}
                      </h2>
                      <h5 class="mb-0">
                        {{ $t('amount') }}
                      </h5>
                    </b-col>
                    <b-col class="p-1 border">
                      <h2 class="mb-0">
                        {{ $helpers.formatDate(order.date_added) }}
                      </h2>
                      <h5 class="mb-0">
                        {{ $t('order_placed') }}
                      </h5>
                    </b-col>
                    <b-col class="p-1 border">
                      <h2 class="mb-0">
                        {{ $helpers.formatDate(order.delivery_date) }}
                      </h2>
                      <h5 class="mb-0">
                        {{ $t('delivery_date') }}
                      </h5>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container class="mt-2 border">
                  <b-row
                    cols="1"
                    class="border"
                  >
                    <b-col class="p-1 border">
                      <h2 class="mb-0">
                        {{ $t('invoice') }}
                      </h2>
                      <a
                        class="text-secondary"
                        :href="order.invoice.receipt"
                        target="_blank"
                      >{{ $t('download_your_invoice_here') }}</a>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container class="mt-2 border">
                  <b-row
                    cols="1"
                    class="border"
                  >
                    <b-col class="p-1 border">
                      <h2 class="mb-0">
                        {{ $t('delivery_address') }}
                      </h2>
                      <h6 class="mb-0">
                        {{ order.recipient.complete }}
                      </h6>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDownloadingCover: false,
      isDownloadingGrn: false,
    }
  },
  methods: {
    async downloadPdf(type) {
      let fileName = ''
      if (type === 'cover') {
        fileName = 'cover-note'
        this.isDownloadingCover = true
      }

      if (type === 'grn') {
        fileName = 'good-return-note'
        this.isDownloadingGrn = true
      }

      const response = await this.$http.get(`/b2b/${type}/id/${this.order.id}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${fileName}-${this.order.date_added}-${this.order.id}.pdf`)
      document.body.appendChild(fileLink)

      fileLink.click()
      this.isDownloadingCover = false
      this.isDownloadingGrn = false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
